/**
 * Theme global.js
 */

'use strict';
var $ = jQuery;

function init()
{
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

jQuery( window ).load(function() {

    //Accordion to FAQ-page. In case of emergency pls check accordion.js also.
    var accordions = document.querySelectorAll('.accordion-list');
    if (accordions.length) {
        for (var i = 0; i < accordions.length; i++) {
            accordions[i].accordion('.accordion-head', '.accordion-item');
        }
    }
});
